import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import QuestionModel from './questionModel'
import ScheduleBlockModel from './AppScheduleBlockModel'

export type AnswerModelStorage = {
  id?: string,
  content?: string,
  value?: number
}

export default class AnswerModel extends BaseEntityModel implements EntityModel<AnswerModel> {
  constructor (
      public id?: string,
      public label?: string,
      public result?: number
  ) {
    super(id)
  }

  public static deserialize (input: any): AnswerModel {
    return new AnswerModel(
      input.id,
      input.content,
      Math.round(input.value * 100)
    )
  }

  public serialize () : Object {

    if (this.id) {
      return {
        id: this.id,
        content: this.label
      }
    } else {
      return {
        content: this.label
      }
    }
  }

  public serializeForStorage () : AnswerModelStorage {
    return {
      id: this.id,
      content: this.label,
      value: this.result
    }
  }

  public isSelected (question: QuestionModel) {
    const filterdAnswers : string[] | undefined = question.answers?.filter((selectedAnserId: string) => this.id === selectedAnserId)

    return filterdAnswers && filterdAnswers.length > 0
  }

}

