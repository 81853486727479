<template>
  <div>
    <demographic-questions-table :isSelectable="false"/>
  </div>
</template>

<script>
import Vue from 'vue'
import demographicQuestionsTableVue from '../../components/tables/demographicQuestionsTable.vue'
import QuestionRepository from '../../repositories/QuestionRepository'
import { useRouter } from '../../composables/useRouter'

export default Vue.extend({
  name: 'DemographicQuestionsOverviewScreen',
  components: {
    'demographic-questions-table': demographicQuestionsTableVue
  },
  data () {
    return {
      fields: [
        { key: 'id', sortable: false, label: '#' },
        { key: 'content', sortable: false, label: 'Info' },
        { key: 'last_asked', sortable: false, label: this.$t('table.last-asked') },
        { key: 'type', sortable: false, label: 'Type' }
      ]
    }
  },
  setup () {
    return {
      QuestionRepository
    }
  }
})
</script>
