import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class FeedbackTypeModel extends BaseEntityModel implements EntityModel<FeedbackTypeModel> {
  constructor (
      public id?: string,
      public value?: string,
      public text?: string
  ) {
    super(id)
  }

  public static deserialize (input: any): FeedbackTypeModel {
    return new FeedbackTypeModel(
      input.id,
      input.value,
      input.label
    )
  }
}

