import FeedbackModel from '@youthapp/shared/src/models/entities/feedbackModel'
import { BaseRepository } from '@youthapp/shared/src/repositories/baseRepository'
import axios from 'axios'

export class FeedbackRepository extends BaseRepository<FeedbackModel> {
  id= 'feedback'
  endpoint= '/feedback/'
  entityModel= FeedbackModel
  publicGet= false

  complete (feedbackId: string, prefix): Promise<any> {
    return this.post(this.endpoint + feedbackId + '/' + prefix + '/', null)
  }

  /*
  questionExport (): Promise<any> {
    return this.get(this.endpoint + 'question_export/')
      .then(response => {
        if (!response.data || response.data.indexOf(',') === -1) {
          throw new Error('No data in the response')
        }
        return response
      })
  }
  */
  questionExport (): Promise<any> {
    return this.get(this.endpoint + 'question_export/')
      .then(response => {
        return response
      })
  }

  tipExport (): Promise<any> {
    return this.get(this.endpoint + 'tip_export/')
      .then(response => {
        return response
      })
  }

  bugReportExport (): Promise<any> {
    return this.get(this.endpoint + 'bug_report_export/')
      .then(response => {
        return response
      })
  }
}
