
export class UserModel {

  constructor (
    public id?: string,
    public firstName?: string,
    public lastName?: string,
    public postCode?: string,
    public birthYear?: string,
    public permissions: Array<string> = [],
    public email?: string,
    public notificationToken?: string,
    public date_joined?: string
  ) {
    return this
  }

  public static deserialize (input: any): UserModel {
    return new UserModel(
      input.id,
      input.first_name,
      input.last_name,
      input.post_code,
      input.birth_year,
      input.permissions,
      input.email,
      input.notification_push_token,
      input.date_joined
    )
  }

  public serialize (): Object {

    return this.notificationToken === undefined
      ? {
        post_code: this.postCode,
        birth_year: this.birthYear
      }
      : {
        post_code: this.postCode,
        birth_year: this.birthYear,
        notification_push_token: this.notificationToken ? this.notificationToken : ''
      }
  }

}
