






import { defineComponent } from '@vue/composition-api'
import ScheduleBlockModel from '@youthapp/shared/src/models/entities/scheduleBlocksModel'

export default defineComponent({
  name: 'no-questions',
  props: {
    scheduleBlock: {
      type: ScheduleBlockModel,
      required: true
    }
  },
  setup ({ scheduleBlock }) {
    const areThereQuestions = (scheduleBlock: ScheduleBlockModel) => {
      if ((scheduleBlock.state.value === 'HISTORIC' || scheduleBlock.state.value === 'ACTIVE') && (!scheduleBlock.questions)) {
        return true
      } else {
        return false
      }
    }

    return {
      areThereQuestions
    }
  }
})
