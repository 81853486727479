import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import OptionsModel from './optionsModel'
import PropertyModel from './propertyModel'


export default class TargetDemographicModel extends BaseEntityModel implements EntityModel<TargetDemographicModel> {
  constructor (
        public id?: string,
        public options?: OptionsModel[],
        public property?: PropertyModel
  ) {
    super(id)
  }

  public static deserialize (input: any): TargetDemographicModel {
    return new TargetDemographicModel(
      input.id,
      input.options,
      input.property
    )
  }

  public serialize () : Object {
    return {
      property: this.id,
      options: this.options
    }
  }
}
