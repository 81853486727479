<template>
  <div>
    <b-col cols="10" class="pr-0">
      <question-form />
    </b-col>
  </div>
</template>

<script>
import Vue from 'vue'
import QuestionForm from '../components/dashboard/forms/QuestionForm.vue'

export default Vue.extend({
  name: 'Tip',
  components: {
    'question-form': QuestionForm
  },
  setup () {
  }
})
</script>
