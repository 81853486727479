









import { defineComponent } from '@vue/composition-api'
import { useRouter } from '../../composables/useRouter'

export default defineComponent({
  name: 'back-button',
  setup () {
    const { router } = useRouter()

    const navigate = () => {
      router.go(-1)
    }

    return {
      navigate
    }
  }
})
