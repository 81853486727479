<template>
  <div>
    <feedback-view />
  </div>
</template>

<script>
import Vue from 'vue'
import FeedbackView from '../components/dashboard/FeedbackView.vue'

export default Vue.extend({
  name: 'Feedback',
  components: {
    'feedback-view': FeedbackView
  }
})
</script>
