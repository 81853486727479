import ResultModal from '../../models/entities/resultModal'
import { BaseRepository, repoParams } from '../baseRepository'

export class ResultRepository extends BaseRepository<ResultModal> {
    id= '/question_results_2/'
    endpoint= '/schedule_blocks/'
    entityModel= ResultModal
    publicGet= false

    getSingle (params: repoParams) : Promise<{result: ResultModal, params: repoParams }> {
      let endpointPartTwo : string = params.id + '/results/'
      if (params.id === 'active_results') { endpointPartTwo = 'active_results/' }

      return this.get(this.endpoint + endpointPartTwo, {}, this.publicGet).then((response: any) => {
        return { result: this.entityModel.deserialize(response), params: params }
      })
    }
}
