











































import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import getValidationState from '../../composables/useValidationState'

export enum inputTypes {
    text = 'text',
    email = 'email',
    time = 'time',
    number = 'number'
}

export default defineComponent({
  name: 'text-input',
  props: {
    value: [String, Number],
    label: String,
    type: String as PropType<inputTypes>,
    id: String,
    disabled: Boolean,
    textarea: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => { return { required: true, min: 3 } }
    },
    prepend: String,
    append: String
  },
  setup (props, { emit }) {

    const input = ref(props.value)

    watch(input, value => {
      emit('input', value)
    })

    watch(() => props.value, () => { input.value = props.value })

    return {
      getValidationState,
      input
    }
  }
})
