import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'


export default class PropertyModel extends BaseEntityModel implements EntityModel<PropertyModel> {
  constructor (
        public id?: string,
        public key?: string,
        public order?: number,
        public created_at?: string
  ) {
    super(id)
  }

  public static deserialize (input: any): PropertyModel {
    return new PropertyModel(
      input.id,
      input.key,
      input.order,
      input.created_at
    )
  }

  public serialize () : Object {
    return {}
  }
}
