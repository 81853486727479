














import Vue from 'vue'
import TableOverview from '../../components/semantic/TableOverview.vue'
import useRepository, { callTypes } from '../../composables/useRepository'
import FeedbackTypeRepository from '../../repositories/feedbackTypeRepository'
import { FeedbackRepository } from '@youthapp/shared/src/repositories/entities/feedbackRepository'
import FeedbackStatusRepository from '../../repositories/feedbackStatusRepository'
import usePermissions from '../../composables/usePermissions'

import { ref } from '@vue/composition-api'

export default Vue.extend({
  name: 'demographic-questions-table',
  components: {
    'table-overview': TableOverview
  },
  props: {
    isSelectable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fields: [
        { key: 'proposedBy', sortable: false, label: 'Voorgesteld door' },
        { key: 'remark', sortable: false, label: 'Comment' },
        { key: 'content', sortable: false, label: 'Vraag/Tip' },
        { key: 'type', sortable: false, label: 'Type' },
        { key: 'createdAt', sortable: false, label: 'Ingezonden' },
        { key: 'status', sortable: false, label: 'Gelezen' }
      ]
    }
  },
  setup () {
    const feedbackTypes = ref<Array<any>>([])
    const feedbackStatuses = ref<Array<any>>([])
    const { can } = usePermissions()

    const getFeedbackTypes = () => {
      const { results, doCall } = useRepository(
        FeedbackTypeRepository,
        callTypes.getModelArray
      )

      doCall().then(() => {
        feedbackTypes.value = results.value
      })
    }

    const getFeedbackStatuses = () => {
      const { results, doCall } = useRepository(
        FeedbackStatusRepository,
        callTypes.getModelArray
      )

      doCall().then(() => {
        feedbackStatuses.value = results.value
      })
    }

    getFeedbackTypes()
    getFeedbackStatuses()

    return {
      FeedbackRepository,
      feedbackStatuses,
      feedbackTypes,
      can
    }
  }
})
