






















































































































































































































































import { ref, PropType, watch, defineComponent } from '@vue/composition-api'
import useRepository, { callTypes } from '@youthapp/dashboard/src/composables/useRepository'
import { BaseRepository, repoParams, filterObject } from '@youthapp/shared/src/repositories/baseRepository'
import { BaseEntityModel } from '@youthapp/shared/src/models/entities/baseEntityModel'
import ScheduleBlockModel from '@youthapp/shared/src/models/entities/scheduleBlocksModel'
import moment from 'moment'
import CloneQuestion from '../../components/dashboard/CloneQuestion.vue'
import RetireQuestion from '../../components/dashboard/RetireQuestion.vue'
import { RepositoryFactory } from '@youthapp/shared/src/repositories/repositoryFactory'
import { ScheduleBlockRepository } from '@youthapp/shared/src/repositories/entities/scheduleBlocksRepository'
import usePermissions from '../../composables/usePermissions'
import useGlobalLoading from '../../composables/useGlobalLoading'
import QuestionRepository from '../../repositories/QuestionRepository'
import TagRepository from '@youthapp/shared/src/repositories/entities/tagRepository'
import MultiSelectInput from '../inputs/MultiSelectInput.vue'
import FeedbackView from '../dashboard/FeedbackView.vue'
import axios from 'axios'


export default defineComponent({
  name: 'table-overview',
  components: {
    'clone-question': CloneQuestion,
    'retire-question': RetireQuestion,
    'multi-select-input': MultiSelectInput,
    'feedback-view': FeedbackView
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    onEmptyResultsMessage: {
      type: String,
      default: ''
    },
    perPage: {
      type: Number,
      default: 25,
      required: false
    },
    repo: {
      type: Function as PropType<new (...params: any[]) => BaseRepository<BaseEntityModel>>,
      required: true
    },
    pathPrefix: {
      type: String,
      required: true
    },
    types: {
      type: Array,
      required: false
    },
    statuses: {
      type: Array,
      required: false
    },
    filterList: {
      type: Array,
      required: true
    },
    isSelectable: {
      type: Boolean,
      required: true
    },
    isSearchable: {
      type: Boolean,
      required: false,
      default: false
    },
    scheduleBlock: {
      type: Object,
      required: false
    },
    activeDate: {
      type: String,
      required: false
    },
    isExport: {
      type: Boolean,
      required: false,
      default: false
    },
    isAnalytics: {
      type: Boolean,
      required: false,
      default: false
    },
    value: Array as PropType<BaseEntityModel[]>
  },
  setup (props, { emit }) {
    const isFeedbackViewVisible = ref<boolean>(false)
    const { can } = usePermissions()
    enum Filters { term = 'term', type = 'type', status = 'status', tags = 'tags' }
    const text = ref<string>('')
    const type = ref<string>('')
    const status = ref<string>('')
    const currentPage = ref<number>(1)
    const selected = ref<BaseEntityModel[]>([])
    let debounce
    const activeDate = ref<any>()
    const selectedTags = ref<Array<any>>([])
    const filterKey = 'title'
    const feedbackId = ref<String>()

    if (props.isAnalytics) {
      activeDate.value = moment.now()
    } else if (!props.activeDate) {
      RepositoryFactory.get(ScheduleBlockRepository).activeScheduleBlock().then((res) => {
        activeDate.value = moment(res.date)
      }).catch((e: { response }) => {
        activeDate.value = moment(e.response.data.active_date)
      })
    } else {
      activeDate.value = moment(props.activeDate)

    }

    const checkIfActiveDate = (date: string) => {
      if (date) {
        const inputDate = moment(date, 'YYYY-MM-DD')
        if (inputDate < activeDate.value) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

    if (props.isSelectable) {
      props.fields.unshift({ key: 'checkbox', sortable: false, label: '' })
    }

    if (props.pathPrefix === 'question') {
      status.value = 'ACTIVE'
    }

    if (props.pathPrefix === 'feedback') {
      status.value = 'NEW'
    }

    if (props.isAnalytics) {
      props.fields.push({ key: 'analytics', sortable: false, label: '' })
    } else {
      props.fields.push({ key: 'edit', sortable: false, label: '' }, { key: 'dropdown', sortable: false, label: '' })
    }

    let filters : filterObject = {
      term: { type: 'string', value: text.value, filterKey: 'term' },
      type: { type: 'string', value: type.value, filterKey: 'type' },
      status: { type: 'string', value: status.value, filterKey: 'status' },
      tags: { type: 'arrayEntity', value: selectedTags.value, filterKey: 'tags' },
      in_use: { type: 'string', value: ((props.isExport === true ? String(props.isExport) : undefined) || (props.isAnalytics === true ? String(props.isAnalytics) : undefined)), filterKey: 'in_use' }
    }

    if (props.pathPrefix === 'demographic') {
      filters = {
        term: { type: 'string', value: 'true', filterKey: 'only_editable' }
      }
    }

    const params : repoParams = { filters: filters, page: currentPage.value, pageSize: Number(props.perPage) }
    const { loading, results, count, doCall } = useRepository(
      props.repo,
      callTypes.getModelArray,
      params
    )

    useGlobalLoading(loading)

    const debounceSearch = (value) => {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        text.value = value
        currentPage.value = 1
        filters[Filters.term] = { type: 'string', value: text.value, filterKey: 'term' }
        doCall()
      }, 400)
    }

    watch(() => selectedTags.value, () => {
      currentPage.value = 1
      filters[Filters.tags] = { type: 'arrayEntity', value: selectedTags.value, filterKey: 'tags' }
      doCall()
    })

    watch(() => type.value, () => {
      currentPage.value = 1
      filters[Filters.type] = { type: 'string', value: type.value, filterKey: 'type' }
      doCall()
    })

    watch(() => status.value, () => {
      currentPage.value = 1
      filters[Filters.status] = { type: 'string', value: status.value, filterKey: 'status' }
      doCall()
    })

    watch(() => currentPage.value, (first) => {
      params.page = first
      doCall()
    })
    // @ts-ignore vue bug, only gives types when deconstructing, v-model is not working when deconstructed
    watch(() => props.value, (inputvalue: BaseEntityModel[]) => {
      selected.value = inputvalue
    })

    doCall()

    const checkBoxChanged = () => {
      emit('input', selected.value)
    }

    const isDisplayable = (tipId: string) => {
      if (props.pathPrefix === 'question') {
        return false
      } else if (props.pathPrefix === 'tip' /* && (selected.value.length < 1 || tipId === selected.value[0].id) */) {
        return false
      } else {
        return true
      }
    }

    const checkIfIdAlreadyExistsInScheduleBlock = (id: string, scheduleBlock: ScheduleBlockModel) => {
      if (scheduleBlock) {
        if ((scheduleBlock.tips && scheduleBlock.tips.find(x => x.tip.id === id)) || (scheduleBlock.questions && scheduleBlock.questions.find(x => x.question.id === id))) {
          return true
        }
      }
      return false
    }

    const onRowSelected = (selectedRow) => {
      if (selectedRow[0]) {
        const checkbox: HTMLInputElement | null = document.getElementById('checkbox-' + selectedRow[0].id) as HTMLInputElement
        if (!checkbox.disabled) {
          checkbox.checked = !checkbox.checked
          emit('input', selectedRow) // is an array
        }
      }
    }

    const reloadData = (event: any) => {
      doCall()
    }

    const displayFeedbackViewSidebar = (id: string) => {
      isFeedbackViewVisible.value = true
      feedbackId.value = id
    }

    let index
    let direction

    const previousFeedback = (id: string) => {
      direction = 'PREVIOUS'
      // @ts-ignore
      index = results.value.findIndex(x => x.id === feedbackId.value)
      if (index === 0) {
        if (currentPage.value > 1) {
          currentPage.value--
        }
      } else {
        // @ts-ignore
        feedbackId.value = results.value[index - 1].id
      }
    }

    watch(() => results.value, () => {
      if (index === 0 && direction === 'PREVIOUS') {
        // @ts-ignore
        feedbackId.value = results.value[props.perPage - 1].id
      }
      if (index === props.perPage - 1 && direction === 'NEXT') {
        // @ts-ignore
        feedbackId.value = results.value[0].id
      }
    })

    const nextFeedback = (id: string) => {
      direction = 'NEXT'
      // @ts-ignore
      index = results.value.findIndex(x => x.id === feedbackId.value)
      if (index === (results.value.length - 1)) {
        if (currentPage.value < (count.value / props.perPage)) {
          currentPage.value++
        }
      } else {
        // @ts-ignore
        feedbackId.value = results.value[index + 1].id
      }
    }

    const shortenContent = (content: string) => {
      if (content.length > 50) {
        content = content.substring(0, 50) + '...'
      }
      return content
    }

    const shortenRemark = (remark: string) => {
      if (remark.length > 50) {
        remark = remark.substring(0, 50) + '...'
      }
      return remark
    }


    return {
      previousFeedback,
      nextFeedback,
      debounceSearch,
      currentPage,
      results,
      count,
      doCall,
      text,
      type,
      status,
      selected,
      checkBoxChanged,
      isDisplayable,
      checkIfIdAlreadyExistsInScheduleBlock,
      onRowSelected,
      checkIfActiveDate,
      reloadData,
      can,
      selectedTags,
      filterKey,
      TagRepository,
      displayFeedbackViewSidebar,
      isFeedbackViewVisible,
      feedbackId,
      shortenRemark,
      shortenContent
    }
  }
})
