







import { defineComponent } from '@vue/composition-api'
import NoAccess from '../dashboard/NoAccess.vue'
import usePermissions from '../../composables/usePermissions'

export default defineComponent({
  name: 'base-page',
  components: {
    'no-access': NoAccess
  },
  setup () {
    const { can, arePermissionsLoaded } = usePermissions()

    return {
      arePermissionsLoaded,
      can
    }
  }
})
