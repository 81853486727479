import { BaseRepository, repoParams } from '../../../shared/src/repositories/baseRepository'
import { RepositoryFactory } from '../../../shared/src/repositories/repositoryFactory'
import { ref, Ref } from '@vue/composition-api'

export enum callTypes {
    getModelArray = 'getModelArray',
    getSingel = 'getSingle',
    create = 'create',
    update = 'update',
    history = 'getHistory'
}

export type useRepositoryType<T> = {
  loading : Ref<Boolean>,
  doCall: () => Promise<Boolean>,
  loadMore: () => void,
  result: Ref<T | undefined>
  results: Ref<T[] >,
  count: Ref<number>
}

export default function useRepository<T> (
  repo: new (...params: any[]) => BaseRepository<T>,
  callType: callTypes = callTypes.getModelArray,
  params: repoParams = {}
) : useRepositoryType<T> {
  const loading = ref<Boolean>(false)
  const activeRepo : BaseRepository<T> = RepositoryFactory.get(repo)
  const result = ref() as Ref<T | undefined>
  const results = ref([]) as Ref<T[]>
  const count = ref<number>(0)

  if (!params.page) {
    params.page = 1
  }

  async function doCall () : Promise<Boolean> {
    loading.value = true
    try {
      if (callType === callTypes.getModelArray) {
        const repoResponse : {result: T[], params: repoParams, count: number } = await activeRepo[callType](params)
        results.value = repoResponse.result
        params = repoResponse.params
        count.value = repoResponse.count
      } else {
        const repoResponse : {result: T, params: repoParams } = await activeRepo[callType](params)
        result.value = repoResponse.result
        params = repoResponse.params
      }

      loading.value = false
      return true
    } catch (error) {
      loading.value = false
      throw error
    }
  }

  async function loadMore () {
    try {
      if (callType === callTypes.getModelArray && !params.isMaxPage) {
        loading.value = true
        params.page && params.page++
        const repoResponse : {result: T[], params: repoParams, count: number } = await activeRepo[callType](params)
        params = repoResponse.params
        results.value = results.value.concat(repoResponse.result)
        loading.value = false
      }
    } catch (e) {
      loading.value = false
      throw new Error(e)
    }
  }

  return {
    loading,
    doCall,
    result,
    results,
    loadMore,
    count
  }
}
