import store from '../store'
import { getModule } from 'vuex-module-decorators'
import userModule from '@/store/userModule'

export type usePermissionsType = {
    can: (permission: string) => boolean,
    arePermissionsLoaded: () => boolean
}

export default function usePermissions () : usePermissionsType {
  const userStoreModule = getModule(userModule, store)

  function can (permission: string) : boolean {
    return userStoreModule.getUser.permissions.includes(permission)
  }

  function arePermissionsLoaded () : boolean {
    if (userStoreModule?.getUser?.permissions.length > 0) {
      return true
    } else {
      return false
    }
  }

  return {
    arePermissionsLoaded,
    can
  }

}
