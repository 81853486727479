import { AxiosRequestConfig } from 'axios'
import { BaseApiRepository, interceptorsType } from './baseApiRepository'
import { Store } from 'vuex'

export type apiUrlType = {
  baseUrl : string
  apiSuffix : string
}

export type featuresType = {
  demographic : boolean,
  feedback : boolean
}
export class RepositoryFactory {
  private static repositoryInstances: any = {}
  static requestTokenInterceptorCallback?: any
  static responseErrorInterceptorCallback?: any
  static vueStore: Store<any>

  private static apiUrl: apiUrlType = {
    baseUrl: '',
    apiSuffix: ''
  }

  private static features: featuresType = {
    demographic: false,
    feedback: false
  }

  public static get<T extends BaseApiRepository> (
    RepositoryClass: new (...params: any[]) => T
  ): T {
    const repositoryId = new RepositoryClass().id

    let repository = this.repositoryInstances[repositoryId]
    if (!repository) {
      repository = new RepositoryClass()

      this.repositoryInstances[repositoryId] = repository
    }

    return repository
  }

  public static setInterceptors (
    request: any,
    response: any,
    store?: Store<any>
  ) {
    this.requestTokenInterceptorCallback = request
    this.responseErrorInterceptorCallback = response
    if (store) this.vueStore = store
  }

  public static setUrl (url: apiUrlType) {
    this.apiUrl = url
  }

  public static getUrl () : apiUrlType {
    return this.apiUrl
  }

  public static setFeatures (features: featuresType) {
    this.features = features
  }

  public static getFeatures () {
    return this.features
  }
}
