import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import TipModel from './tipModel'
import TargetDemographicModel from './targetDemographicModel'


export default class TipInstanceModel extends BaseEntityModel implements EntityModel<TipInstanceModel> {
  constructor (
    public tip: TipModel,
    public id?: string,
    public target_demographics?: TargetDemographicModel[],
    public coverage?: number,
    public isUpdating: boolean = false,
    public read_tip_count?: number
  ) {
    super(id)
  }

  public static deserialize (input: any): TipInstanceModel {
    return new TipInstanceModel(
      input.tip && TipModel.deserialize(input.tip),
      input.id,
      input.target_demographics,
      Math.round(input.coverage * 100),
      input.has_read_tip,
      input.read_tip_count
    )
  }

  public serialize () : Object {

    const newTargetDemographics: Array<any> = []

    if (this.target_demographics) {
      this.target_demographics.forEach(targetDemographic => {
        const newOptions: Array<any> = []
        if (targetDemographic.options) {
          targetDemographic.options.forEach(option => {
            if (option.isChecked && option.isChecked) {
              newOptions.push(option.id)
            }
            if (!this.isUpdating) {
              newOptions.push(option.id)
            }
          })
          if (newOptions.length > 0) {
            newTargetDemographics.push({ property: targetDemographic?.property?.id, options: newOptions })
          }
        }
      })
    }

    return {
      id: this.id,
      tip: this.tip.id,
      target_demographics: newTargetDemographics
    }
  }
}
