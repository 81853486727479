import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class DemographicClusterGroupsModel extends BaseEntityModel implements EntityModel<DemographicClusterGroupsModel> {
  constructor (
    public id?: string,
    public value?: string,
    public text?: string
  ) {
    super(id)
  }

  public static deserialize (input: any): DemographicClusterGroupsModel {
    return new DemographicClusterGroupsModel(
      input.id,
      input.value,
      input.label
    )
  }
}

