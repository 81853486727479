import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { MasterConfig } from '@youthapp/shared'

@Module({ namespaced: true, name: 'config' })
export default class ConfigModule extends VuexModule {

  // Data
  config: MasterConfig = new MasterConfig()

  // Mutations
  @Mutation
  setConfig (config: any) {
    this.config = config
  }

}
