
































































































































import BackButton from '../components/buttons/backButton.vue'
import { useRouter } from '../composables/useRouter'
import { defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import { RepositoryFactory } from '@youthapp/shared/src/repositories/repositoryFactory'
import QuestionRepository from '@youthapp/dashboard/src/repositories/QuestionRepository'
import MultiSelectInput from '../components/inputs/MultiSelectInput.vue'
import usePermissions from '../composables/usePermissions'
import html2canvas from 'html2canvas'

export default defineComponent({
  name: 'Analytics',
  components: {
    'back-button': BackButton,
    'multi-select-input': MultiSelectInput
  },
  setup () {
    const { can } = usePermissions()
    moment.locale('nl-be')
    const { route } = useRouter()
    const questionId = ref<string>(route.value.params.id)
    const title = ref<string>('')
    const instances = ref<Array<any>>([])
    const selectedInstance = ref<any>()
    const result = ref<any>()
    const loadingResponses = ref<Boolean>()
    const selectedResultOption = ref<String>()
    const selectedDemographic = ref<any>()
    const demographicPropertyFilters = ref<Array<any>>([])
    const benchmark = ref<any>()
    const showBenchmark = ref<Boolean>(true)
    const legend = ref<Array<any>>([])
    const selectedDemographicOption = ref<any>()
    const selectedDemographicOptions = ref<Array<any>>([])

    RepositoryFactory.get(QuestionRepository).questionInstances(questionId.value).then((res) => {
      title.value = res.content
      let sortedInstances = res.instances.sort((a: any, b: any) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })
      const yesterday = Date.now() - 86400000
      sortedInstances = sortedInstances.filter(instance => new Date(instance.date).getTime() < yesterday)
      instances.value = sortedInstances
      selectedInstance.value = sortedInstances[0]
      selectedResultOption.value = 'basic'
      retrieveResults()
    })

    const retrieveResults = () => {
      loadingResponses.value = true
      if (selectedResultOption.value === 'filters') {
        // Check if demographic options were selected
        if (selectedDemographicOptions.value.length > 0) {
          let optionsString = selectedDemographicOptions.value[0].option
          for (let i = 1; i < selectedDemographicOptions.value.length; i++) {
            optionsString += ',' + selectedDemographicOptions.value[i].option
          }
          RepositoryFactory.get(QuestionRepository).questionInstanceResults(selectedInstance.value.id, false, true, selectedDemographic.value.property, optionsString).then((res) => {
            result.value = res
            showBenchmark.value = true
            // Since the filters are all options of a single demographic, only 1 demographic result collection will be returned
            processDemographicResults(res.demographic_results[0])
            loadingResponses.value = false
          })
        } else {
          RepositoryFactory.get(QuestionRepository).questionInstanceResults(selectedInstance.value.id, false, true).then((res) => {
            result.value = res
            showBenchmark.value = false
            demographicPropertyFilters.value = res.demographic_results
            let demographic_key
            // This makes sure the filter UI doesn't fully reset when removing last demographic option as filter
            if (selectedDemographic.value) {
              demographic_key = selectedDemographic.value.property
              selectedDemographic.value = null
              selectDemographicFilter(res.demographic_results.filter(d => d.property === demographic_key)[0])
            } else {
              retrieveBenchmarks()
              selectDemographicFilter(res.demographic_results[0])
            }
            loadingResponses.value = false
          })
        }
      } else if (selectedResultOption.value === 'weighted') {
        RepositoryFactory.get(QuestionRepository).questionInstanceResults(selectedInstance.value.id, true, false).then((res) => {
          selectedDemographic.value = null
          selectedDemographicOptions.value = []
          result.value = res
          loadingResponses.value = false
        })
      } else {
        RepositoryFactory.get(QuestionRepository).questionInstanceResults(selectedInstance.value.id, false, false).then((res) => {
          selectedDemographic.value = null
          selectedDemographicOptions.value = []
          result.value = res
          loadingResponses.value = false
        })
      }
    }

    const checkActive = (id) => {
      if (selectedResultOption.value === id) {
        return 'active'
      }
      return ''
    }

    const setResultOption = (option) => {
      if (selectedResultOption.value !== option) {
        selectedResultOption.value = option
        retrieveResults()
      }
    }

    const getRandomColor = (option) => {
      for (const l of legend.value) {
        if (l.label === option) {
          return l.color + ' !important'
        }
      }
      let randomColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16)
      if (randomColor.length < 7) {
        randomColor = randomColor + 'f'
      }
      legend.value.push({ label: option, color: randomColor })
      return randomColor + ' !important'
    }

    const selectDemographicFilter = (demographic) => {
      if (demographic !== selectedDemographic) {
        selectedDemographicOptions.value = []
        selectedDemographicOption.value = null
        selectedDemographic.value = demographic
        processDemographicResults(demographic)
      }
    }

    const processDemographicResults = (demographic) => {
      loadingResponses.value = true
      legend.value = []
      const processedResults = result.value
      processedResults.total_responses = demographic.total_responses
      // first clear the results so we can fill in the filtered results into this result object
      for (let r = 0; r < processedResults.results.length; r++) {
        processedResults.results[r].value = 0
        processedResults.results[r].optionResults = []
      }
      // prep the bar values
      for (const option of demographic.option_results) {
        if (option.total_responses > 0) {
          for (let i = 0; i < option.results.length; i++) {
            if (option.results[i].value > 0) {
              const correctedResult = option.results[i].value * option.total_responses
              processedResults.results[i].value += correctedResult
              processedResults.results[i].optionResults.push({ id: Math.floor(Math.random() * 1000000), option: option.option, value: correctedResult })
            }
          }
        }
      }
      // correct the values based on the number of optionresults
      if (processedResults.total_responses > 0) {
        for (const r of processedResults.results) {
          if (r.optionResults) {
            r.value = r.value / processedResults.total_responses
            for (const opr of r.optionResults) {
              opr.value = opr.value / processedResults.total_responses
            }
          }
        }
      }
      result.value = processedResults
      loadingResponses.value = false
    }

    const addSelectedDemographicOption = () => {
      if (!selectedDemographicOptions.value.includes(selectedDemographicOption.value)) {
        selectedDemographicOptions.value.push(selectedDemographicOption.value)
      }
      selectedDemographicOption.value = null
      retrieveResults()
    }

    const removeDemographicOption = (option) => {
      for (let i = 0; i < selectedDemographicOptions.value.length; i++) {
        if (selectedDemographicOptions.value[i] === option) {
          selectedDemographicOptions.value.splice(i, 1)
        }
      }
      retrieveResults()
    }

    const retrieveBenchmarks = () => {
      RepositoryFactory.get(QuestionRepository).questionInstanceResults(selectedInstance.value.id, false, false).then((bench_res) => {
        benchmark.value = bench_res
      })
    }

    const getBenchmark = (answer) => {
      for (const r of benchmark.value.results) {
        if (r.content === answer) {
          return r.value
        }
      }
    }

    const download = () => {
      const filename = title.value.replace(/ /g, '_') + '_' + selectedInstance.value.date + '.png'
      const header = document?.getElementById('download-header') as HTMLElement
      const downloadButton = document?.getElementById('download-button') as HTMLElement
      const capture = document?.getElementById('capture') as HTMLElement
      downloadButton.hidden = true
      header.hidden = false
      html2canvas(capture).then(canvas => {
        const c = canvas as HTMLCanvasElement
        const link = document?.createElement('a')
        link.setAttribute('download', filename)
        link.setAttribute(
          'href',
          c.toDataURL('image/png').replace('image/png', 'image/octet-stream')
        )
        link.click()
        downloadButton.hidden = false
        header.hidden = true
      })
    }

    return {
      can,
      questionId,
      title,
      instances,
      selectedInstance,
      retrieveResults,
      result,
      loadingResponses,
      selectedResultOption,
      demographicPropertyFilters,
      checkActive,
      setResultOption,
      getRandomColor,
      selectDemographicFilter,
      benchmark,
      legend,
      selectedDemographic,
      selectedDemographicOption,
      selectedDemographicOptions,
      addSelectedDemographicOption,
      removeDemographicOption,
      processDemographicResults,
      showBenchmark,
      getBenchmark,
      retrieveBenchmarks,
      download
    }
  }
})
