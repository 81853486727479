<template>
  <div>
    <b-col cols="10" class="pr-0">
      <demographic-question-form />
    </b-col>
  </div>
</template>

<script>
import Vue from 'vue'
import DemographicQuestionForm from '../components/dashboard/forms/DemographicQuestionForm.vue'

export default Vue.extend({
  name: 'Demographic-question',
  components: {
    'demographic-question-form': DemographicQuestionForm
  },
  setup () {
  }
})
</script>
