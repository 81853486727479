import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import QuestionModel from './questionModel'
import TargetDemographicModel from './targetDemographicModel'


export default class QuestionInstanceModel extends BaseEntityModel implements EntityModel<QuestionInstanceModel> {
  constructor (
      public question: QuestionModel,
      public id?: string,
      public target_demographics?: TargetDemographicModel[],
      public coverage?: number,
      public isUpdating: boolean = false
  ) {
    super(id)
  }

  public static deserialize (input: any): QuestionInstanceModel {
    return new QuestionInstanceModel(
      input.question && QuestionModel.deserialize(input.question),
      input.id,
      input.target_demographics,
      Math.round(input.coverage * 100)
    )
  }

  public serialize () : Object {

    const newTargetDemographics: Array<any> = []

    if (this.target_demographics) {
      this.target_demographics.forEach(targetDemographic => {
        const newOptions: Array<any> = []
        if (targetDemographic.options) {
          targetDemographic.options.forEach(option => {
            if (option.isChecked && option.isChecked) {
              newOptions.push(option.id)
            }
            if (!this.isUpdating) {
              newOptions.push(option.id)
            }
          })
          if (newOptions.length > 0) {
            newTargetDemographics.push({ property: targetDemographic?.property?.id, options: newOptions })
          }
        }
      })
    }

    return {
      id: this.id,
      question: this.question.id,
      target_demographics: newTargetDemographics
    }
  }
}
