import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios'
import { Buffer } from 'buffer'
import { apiUrlType, RepositoryFactory } from './repositoryFactory'
// import { getModule } from 'vuex-module-decorators'
// import store from '../store/store'
// import configModule from '../store/configModule'
// import MasterConfig from '../models/config/masterConfig'
// import { OpenIdConnectInterceptors } from 'inuits-vuejs-oidc'

export type interceptorsType = {
  request: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>,
  response: (_error: any) => any
}
export abstract class BaseApiRepository {
  private axiosInstance: AxiosInstance
  private publicAxiosInstance: AxiosInstance
  abstract id: string

  constructor () {
    // const config: MasterConfig = getModule(configModule, store).config

    const config = {
      api: RepositoryFactory.getUrl()
    }

    this.axiosInstance = axios.create({
      baseURL: config.api.baseUrl + '/' + config.api.apiSuffix
    })
    this.publicAxiosInstance = axios.create({
      baseURL: config.api.baseUrl + '/' + config.api.apiSuffix
    })

    // Add oidc interceptors
    if (RepositoryFactory.requestTokenInterceptorCallback && RepositoryFactory.responseErrorInterceptorCallback) {
      const requestInterceptor = RepositoryFactory.vueStore ? RepositoryFactory.requestTokenInterceptorCallback(RepositoryFactory.vueStore) : RepositoryFactory.requestTokenInterceptorCallback

      this.axiosInstance.interceptors.request.use(
        requestInterceptor
      )

      this.axiosInstance.interceptors.response.use(
        function (response) { return response },
        (error) => {
          if (RepositoryFactory.responseErrorInterceptorCallback && RepositoryFactory.vueStore) {
            return RepositoryFactory.responseErrorInterceptorCallback(error, RepositoryFactory.vueStore)
          } else {
            return RepositoryFactory.responseErrorInterceptorCallback(error)
          }
        }
      )

    }
  }

  private isPublicCall (isPublic: boolean) : AxiosInstance {
    return isPublic ? this.publicAxiosInstance : this.axiosInstance
  }


  protected get (endpoint: string, config: AxiosRequestConfig = {}, publicCall: boolean = false): Promise<any> {
    return this.isPublicCall(publicCall).get(endpoint, config).then(function (result: AxiosResponse) {
      // Only return the data of response
      return result.data
    })
  }

  protected getFile (endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
    config = {
      ...config,
      responseType: 'arraybuffer'
    }
    return this.axiosInstance.get(endpoint, config).then(function (result: AxiosResponse) {
      // Only return the data of response
      return Buffer.from(result.data, 'binary').toString('base64')
    })
  }

  protected post (endpoint: string, data: any, config: AxiosRequestConfig = {}, publicCall: boolean = false): Promise<any> {
    return this.isPublicCall(publicCall).post(endpoint, data, config).then(function (result: AxiosResponse) {
      // Only return the data of response

      return result.data
    })
  }

  protected patch (endpoint: string, data: any, config: AxiosRequestConfig = {}): Promise<any> {

    return this.axiosInstance.patch(endpoint, data, config).then(function (result: AxiosResponse) {
      // Only return the data of response
      return result.data
    })
  }

  protected delete (endpoint: string): Promise<any> {
    return this.axiosInstance.delete(endpoint).then(function (result: AxiosResponse) {
      // Only return the data of response
      return result.data
    })
  }

  private processError (error: any) : void {
  }

}
