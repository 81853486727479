import { MasterConfig } from '../models/config/masterConfig'

export class EnvRepository {

  public GetMasterConfig (): MasterConfig {
    return new MasterConfig().deserialize({
      api: {
        baseUrl: process.env.VUE_APP_BASEURL,
        apiSuffix: process.env.VUE_APP_APISUFFIX
      },
      oidc: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        serverBaseUrl: process.env.VUE_APP_SERVER_BASE_URL,
        authEndpoint: process.env.VUE_APP_AUTH_ENDPOINT,
        logoutEndpoint: process.env.VUE_APP_LOGOUT_ENDPOINT,
        clientId: process.env.VUE_APP_CLIENT_ID
      }
    })
  }

}
