import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
// import nl from 'vee-validate/dist/locale/nl.json'
import { EnvRepository, MasterConfig, RepositoryFactory, StaticFileRepository } from '@youthapp/shared'
import { OpenIdConnectInterceptors, OpenIdConnectPlugin } from 'inuits-vuejs-oidc'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import configModule from './store/configModule'

import './main.scss'
import { getModule } from 'vuex-module-decorators'
import userModule from './store/userModule'
import i18n from './i18n'
import AuthRepository from '@youthapp/shared/src/repositories/authRepository'

Vue.config.productionTip = false
Vue.config.productionTip = false

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

// localize('nl', nl)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.use(VueCompositionAPI)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Load config
new StaticFileRepository().getFile('config.json')
  .then((configFile: any) => {
    configFile = new MasterConfig().deserialize(configFile)
    if (configFile.oidc && configFile.oidc.baseUrl && configFile.oidc.clientId) {

      Vue.use(OpenIdConnectPlugin, {
        store: store,
        router: router,
        configuration: {
          baseUrl: configFile.oidc.baseUrl,
          serverBaseUrl: configFile.oidc.serverBaseUrl,
          tokenEndpoint: configFile.oidc.tokenEndpoint ? configFile.oidc.tokenEndpoint : 'token',
          authEndpoint: configFile.oidc.authEndpoint ? configFile.oidc.authEndpoint : 'auth',
          logoutEndpoint: configFile.oidc.logoutEndpoint ? configFile.oidc.logoutEndpoint : 'logout',
          clientId: configFile.oidc.clientId,
          authorizedRedirectRoute: '/',
          serverTokenEndpoint: 'token/',
          serverRefreshEndpoint: 'refresh/',
          demographic: configFile.features?.demographic ? configFile.features?.demographic : false,
          feedback: configFile.features?.feedback ? configFile.features?.feedback : false

        }
      })
    }

    const configStoreModule = getModule(configModule, store)
    configStoreModule.setConfig(configFile)

    RepositoryFactory.setUrl({
      baseUrl: configFile.api.baseUrl,
      apiSuffix: configFile.api.apiSuffix
    })

    RepositoryFactory.setFeatures({
      demographic: configFile.features?.demographic ? configFile.features?.demographic : false,
      feedback: configFile.features?.feedback ? configFile.features?.feedback : false
    })

    RepositoryFactory.setInterceptors(
      OpenIdConnectInterceptors.buildRequestTokenInterceptorCallback,
      OpenIdConnectInterceptors.buildResponseErrorInterceptorCallback,
      store
    )

    router.beforeEach((to: any, from: any, next: any) => {
      if (to.matched.some((record: any) => record.meta.requiresOpenIdAuth)) {
        const userStoreModule = getModule(userModule, store)
        if (store.getters['openid/isLoggedIn']) {
          RepositoryFactory.get(AuthRepository).me().then((user: any) => {
            userStoreModule.setMe(user.result).then(() => {
              next()
              // console.log('user: ', user)
            })
          })
        } else {
          next()
        }
      } else {
        next()
      }
    })

    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App)
    }).$mount('#app')

  })
