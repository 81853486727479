
/* eslint-disable camelcase */
import EntityModel from '../../interfaces/entityModel'
import BadgeStatsModel from './BadgeStatsModel'
import { BaseEntityModel } from './baseEntityModel'
import QuestionInstanceModel from './questionInstanceModel'
import QuestionModel, { QuestionModelStorage } from './questionModel'
import ResultModal from './resultModal'
import scheduleBlockStateModel from './scheduleBlockStateModel'
import TipInstanceModel from './tipInstanceModel'
import TipModel, { TipModelStorage } from './tipModel'

type ScheduleBlockModelStorageType = {
  id?: string,
  questions: QuestionModelStorage[],
  tips?: TipModelStorage[],
  has_responded: boolean
}

export default class ScheduleBlockModel extends BaseEntityModel implements EntityModel<ScheduleBlockModel> {
  constructor (
      public id?: string,
      public date?: string,
      public end?: string,
      public start_date_next?: string,
      public state: scheduleBlockStateModel = scheduleBlockStateModel.deserialize({ id: '', value: '', label: '' }),
      public questions?: QuestionInstanceModel[],
      public questionsAnswerd: boolean = false,
      public tips?: TipInstanceModel[],
      public read_tip_count?: number,
      public results?: ResultModal,
      public badgesStatus?: BadgeStatsModel,
      public resultCount?: number,
      public expires?: Date
  ) {
    super(id)
  }

  public static deserialize (input: any): ScheduleBlockModel {
    return new ScheduleBlockModel(
      input.id,
      input.date,
      input.end,
      input.start_date_next,
      input.state,
      this.mapEntityArray(input.question_instances, QuestionInstanceModel),
      input.has_responded ? input.has_responded : false,
      this.mapEntityArray(input.tip_instances, TipInstanceModel),
      input.read_tip_count,
      undefined,
      undefined
    )
  }

  public serialize () : Object {

    const questions : Array<any> = []
    this.questions && this.questions.forEach((question: QuestionInstanceModel) => {
      questions.push(question.serialize())
    })

    const tips : Array<any> = []
    this.tips && this.tips.forEach((tip: TipInstanceModel) => {
      tips.push(tip.serialize())
    })

    return {
      date: this.date,
      end: this.end,
      question_instances: questions,
      tip_instances: tips
    }
  }

}

