<template>
  <div>
    <b-col cols="10" class="pr-0">
      <tip-form />
    </b-col>
  </div>
</template>

<script>
import Vue from 'vue'
import TipForm from '../components/dashboard/forms/TipForm'

export default Vue.extend({
  name: 'Tip',
  components: {
    'tip-form': TipForm
  },
  setup () {
  }
})
</script>
