















import Vue from 'vue'
import DemographicQuestionRepository from '@youthapp/shared/src/repositories/entities/demographicQuestionRepository'
import TableOverview from '../../components/semantic/TableOverview.vue'
import usePermissions from '../../composables/usePermissions'
import NoAccess from '../dashboard/NoAccess.vue'

export default Vue.extend({
  name: 'demographic-questions-table',
  components: {
    'table-overview': TableOverview,
    'no-access': NoAccess
  },
  props: {
    isSelectable: {
      type: Boolean,
      required: true
    },
    scheduleBlock: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      fields: [
        { key: 'key', sortable: false, label: 'Key' },
        { key: 'created_at', sortable: false, label: 'Aangemaakt' },
        { key: 'order', sortable: false, label: 'Volgorde' }
      ]
    }
  },
  setup (isSelectable, { emit }) {
    const { can, arePermissionsLoaded } = usePermissions()
    return {
      DemographicQuestionRepository,
      arePermissionsLoaded,
      can
    }
  }
})
