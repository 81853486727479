import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import OptionsModel from '@youthapp/shared/src/models/entities/optionsModel'
import moment from 'moment'

export enum selectOptions {
  SELECTED = 'SELECT',
  MULTISELECT = 'MULTISELECT'
}

export default class DemographicQuestionModel extends BaseEntityModel implements EntityModel<DemographicQuestionModel> {
  constructor (
    public id?: string,
    public key?: string,
    public order?: number,
    public options?: OptionsModel[],
    public created_at?: string,
    public question_type?: string,
    public question_content?: string,
    public isMultiple?: boolean,
    public is_weighted?: boolean
  ) {
    super(id)
  }

  public static deserialize (input: any): DemographicQuestionModel {
    // sorts the options for demographic properties with a large collection of options (like Single Postcode +/- 1100 options)
    const sortedOptions = input.options ? BaseEntityModel.mapEntityArray(input.options, OptionsModel) : []
    if (sortedOptions.length > 25) {
      sortedOptions.sort((a, b) => { return a.key - b.key })
    }
    return new DemographicQuestionModel(
      input.id,
      input.key,
      input.order,
      sortedOptions,
      moment(input.created_at).format('YYYY-MM-DD H:mm'),
      input.question_type ? input.question_type.value : undefined,
      input.question_content,
      input.question_type && input.question_type.value && input.question_type.value === selectOptions.MULTISELECT,
      input.is_weighted
    )
  }

  public serialize () : Object {
    if (this.options) {
      this.options.forEach(option => {
        if (option?.cluster_group?.value) {
          option.cluster_group = option.cluster_group?.value
        } else {
          option.cluster_group = undefined
        }
      })
    }
    return {
      key: this.key,
      order: this.order,
      options: this.options,
      question_content: this.question_content,
      question_type: this.isMultiple ? selectOptions.MULTISELECT : selectOptions.SELECTED,
      is_weighted: this.is_weighted
    }
  }
}

