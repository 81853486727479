import { BaseRepository, repoParams } from './baseRepository'
import { UserModel } from '../models/userModel'

export default class AuthRepository extends BaseRepository<UserModel> {
  id = 'authrepo'
  endpoint= '/auth/'
  entityModel= UserModel
  publicGet= false

  me () : Promise<{result: UserModel, params: repoParams }> {
    return this.getSingle({ id: 'me' }).then((result: {result: UserModel, params: repoParams }) => {
      return result
    })
  }

}
