import Serializable from '../../interfaces/serializable'

export default class FeaturesConfig implements Serializable<FeaturesConfig> {
  public demographic!: boolean
  public feedback!: boolean


  deserialize (input: any) {
    this.demographic = input.demographic
    this.feedback = input.feedback

    if (this.demographic === undefined) {
      this.demographic = false
    }
    if (this.feedback === undefined) {
      this.feedback = false
    }
    return this
  }
}
