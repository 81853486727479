




















import { FeedbackRepository } from '@youthapp/shared/src/repositories/entities/feedbackRepository'
import QuestionsTable from '../../components/tables/questionsTable.vue'
import TipsTable from '../../components/tables/tipsTable.vue'
import usePermissions from '../../composables/usePermissions'
import ReportRepository from '@youthapp/shared/src/repositories/entities/reportRepository'
import { RepositoryFactory } from '@youthapp/shared'
import ReportModel from '@youthapp/shared/src/models/entities/reportModel'
import { defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import useToast from '../../composables/useToast'
import axios from 'axios'

export default defineComponent({
  name: 'ExportQuestions',
  components: {
    'questions-table': QuestionsTable,
    'tips-table': TipsTable
  },
  setup (props, context) {
    const toast = useToast(context.root)
    const loading = ref<Boolean>(false)
    const { can } = usePermissions()
    const clicked = ref<Boolean>(false)
    const mapQuestions = (e) => {
      loading.value = true
      const report = new ReportModel(undefined, [])
      e.forEach(question => {
        if (report?.questions) {
          report.questions.push(question.id)
        }
      })
      fetchReport(report)
    }

    const downloadQuestionFile = async () => {
      try {
        const getQuestionRepository = RepositoryFactory.get(FeedbackRepository)
        const response = await getQuestionRepository.questionExport()
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'feedback_question_export.csv')
          document.body.appendChild(link)
          link.click()
        }
      } catch (error) {
        toast.send('Something went wrong: ' + error, 'danger')
      }
    }

    const downloadTipFile = async () => {
      try {
        const getQuestionRepository = RepositoryFactory.get(FeedbackRepository)
        const response = await getQuestionRepository.tipExport()
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'feedback_tip_export.csv')
          document.body.appendChild(link)
          link.click()
        }
      } catch (error) {
        toast.send('Something went wrong: ' + error, 'danger')
      }
    }

    const downloadBugReportFile = async () => {
      try {
        const getQuestionRepository = RepositoryFactory.get(FeedbackRepository)
        const response = await getQuestionRepository.bugReportExport()
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'feedback_bugreport_export.csv')
          document.body.appendChild(link)
          link.click()
        }
      } catch (error) {
        toast.send('Something went wrong: ' + error, 'danger')
      }
    }


    const fetchReport = (report: ReportModel) => {
      RepositoryFactory.get(ReportRepository).exportQuestionsReport(report).then((res) => {
        if (res) {
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel'
          })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = 'Ruwe_Export_' + moment().format('YYYY_MM_DD') + '.xlsx'
          a.click()
          window.URL.revokeObjectURL(url)
          clicked.value = false
          loading.value = false
        } else {
          clicked.value = false
          loading.value = false
          toast.send('Something went wrong.', 'danger')
        }
      }).catch(() => {
        clicked.value = false
        loading.value = false
        toast.send('Something went wrong.', 'danger')
      })
    }

    return {
      can,
      mapQuestions,
      downloadQuestionFile,
      downloadTipFile,
      downloadBugReportFile,
      loading
    }
  }
})
