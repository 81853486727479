import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import PageNotFound from '../components/dashboard/PageNotFound.vue'
import DemographicQuestionsOverviewScreen from '../views/overview/DemographicQuestionsOverviewScreen.vue'
import QuestionsOverviewScreen from '../views/overview/QuestionsOverviewScreen.vue'
import FeedbackOverviewScreen from '../views/overview/FeedbackOverviewScreen.vue'
import ExportOverviewScreen from '../views/overview/ExportOverviewScreen.vue'
import TipsOverviewScreen from '../views/overview/TipsOverviewScreen.vue'
import DemographicQuestion from '../views/DemographicQuestion.vue'
import AnalyticsOverviewScreen from '../views/overview/AnalyticsOverviewScreen.vue'
import Calendar from '../views/Calendar.vue'
import Feedback from '../views/Feedback.vue'
import Question from '../views/Question.vue'
import Tip from '../views/Tip.vue'
import Day from '../views/Day.vue'
import Analytics from '../views/Analytics.vue'


Vue.use(VueRouter)

const EmptyRouterView = {
  template: '<router-view></router-view>'
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/calendar',
    component: EmptyRouterView,
    meta: {
      requiresOpenIdAuth: true
    },
    children: [
      {
        path: '/calendar/:currentDay?',
        name: 'calendar',
        component: Calendar,
        meta: {
          title: 'Calendar',
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'demographic-questions-overview-screen',
        name: 'demographic-questions-overview-screen',
        component: DemographicQuestionsOverviewScreen,
        meta: {
          title: 'Demographics Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'feedback-overview-screen',
        name: 'feedback-overview-screen',
        component: FeedbackOverviewScreen,
        meta: {
          title: 'Feedback Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'feedback/:id?',
        name: 'feedback',
        component: Feedback,
        meta: {
          title: 'Feedback',
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'questions-overview-screen',
        name: 'questions-overview-screen',
        component: QuestionsOverviewScreen,
        meta: {
          title: 'Questions Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'question/:id?',
        name: 'question',
        component: Question,
        meta: {
          title: 'Question',
          date: undefined,
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'demographic-question/:id?',
        name: 'demographic-question',
        component: DemographicQuestion,
        meta: {
          title: 'Demographic',
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'tips-overview-screen',
        name: 'tips-overview-screen',
        component: TipsOverviewScreen,
        meta: {
          title: 'Tips Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'tip/:id?',
        name: 'tip',
        component: Tip,
        meta: {
          title: 'Tip',
          date: undefined,
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'day/:date?',
        name: 'day',
        component: Day,
        meta: {
          title: 'Day',
          requiresOpenIdAuth: true
        }
      },
      {
        path: 'export-overview-screen',
        name: 'export-overview-screen',
        component: ExportOverviewScreen,
        meta: {
          title: 'Export Overview',
          requiresOpenIdAuth: true
        }
      }
      // {
      //   path: '*',
      //   name: 'page-not-found',
      //   component: PageNotFound,
      //   meta: {
      //     title: 'Page Not Found'
      //   }
      // }
    ]
  },
  {
    path: '/analytics-overview-screen',
    name: 'analytics-overview-screen',
    component: AnalyticsOverviewScreen,
    meta: {
      title: 'Analytics Overview'
    }
  },
  /*
  // if analytics-overview-screen is changed for logged in users
  {
    path: '/analytics-overview-screen-secure',
    name: 'analytics-overview-screen-secure',
    component: AnalyticsOverviewScreen,
    meta: {
      title: 'Analytics Overview',
      requiresOpenIdAuth: true
    }
  },
  */
  {
    path: '/analytics/:id?',
    name: 'analytics',
    component: Analytics,
    meta: {
      title: 'Analytics',
      requiresOpenIdAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = to.meta.title
    to.meta.date = from.params.date
    next()
  }
})
/*
// in case 'analytics-overview-screen-secure' is added
router.beforeEach((to, from, next) => {
  if (to.name === 'analytics-overview-screen') {
    next({ name: 'analytics-overview-screen-secure' });
  } else if (to.name) {
    document.title = to.meta.title
    to.meta.date = from.params.date
    next()
  }
 */
export default router
