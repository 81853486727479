








import QuestionsTable from '../../components/tables/questionsTable.vue'
import usePermissions from '../../composables/usePermissions'
import { defineComponent, ref } from '@vue/composition-api'
import useToast from '../../composables/useToast'

export default defineComponent({
  name: 'Analytics',
  components: {
    'questions-table': QuestionsTable
  },
  setup (props, context) {
    const toast = useToast(context.root)
    const loading = ref<Boolean>(false)
    const { can } = usePermissions()
    const clicked = ref<Boolean>(false)

    return {
      can,
      loading
    }
  }
})
