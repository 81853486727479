











import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'calendar-selector',
  setup (props, context) {
    const date = ref<Date>()

    const onContext = () => {
      context.emit('selectedDate', date.value)
    }

    return {
      onContext,
      date
    }
  }
})
