<template>
  <div v-if="can('tips.view_tip')">
    <tips-table :isSelectable="false" />
  </div>
</template>

<script>
import Vue from 'vue'
import TipsTable from '../../components/tables/tipsTable.vue'
import TipForm from '../../components/dashboard/forms/TipForm'
import usePermissions from '../../composables/usePermissions'

export default Vue.extend({
  name: 'Tips',
  components: {
    'tips-table': TipsTable
  },
  setup () {
    const { can } = usePermissions()
    return {
      can
    }
  }
})
</script>
