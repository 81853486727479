import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class TagModel extends BaseEntityModel implements EntityModel<TagModel> {
  constructor (
    public id?: string,
    public title?: string
  ) {
    super(id)
  }

  public static deserialize (input: any): TagModel {
    return new TagModel(
      input.id,
      input.title
    )
  }

  public serialize (): Object {
    return {
      title: this.title
    }
  }
}
