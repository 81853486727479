import { AppScheduleBlockRepositoryInterface } from '../../interfaces/baseRepositoryInterface'
import AppScheduleBlockModel from '../../models/entities/AppScheduleBlockModel'
import { BaseRepository, repoParams } from '../baseRepository'

export class AppScheduleBlockRepository extends BaseRepository<AppScheduleBlockModel> implements AppScheduleBlockRepositoryInterface<AppScheduleBlockModel> {
    id= '/app_schedule_blocks/'
    endpoint= '/schedule_blocks/'
    entityModel= AppScheduleBlockModel
    publicGet= false

    respond (params: repoParams) : Promise<{result: AppScheduleBlockModel, params: repoParams }> {
      const model : AppScheduleBlockModel | undefined = (params.model as AppScheduleBlockModel).serializeForRespond !== undefined ? params.model as AppScheduleBlockModel : undefined

      return this.post(this.endpoint + '' + params.id + '/respond/', model && model.serializeForRespond()).then((response: any) => {
        return { result: this.entityModel.deserialize(response), params: params }
      })
    }

    tipRead (params: repoParams, tip_instance_id?: string) : Promise<{result: AppScheduleBlockModel, params: repoParams }> {
      return this.post(this.endpoint + '' + params.id + '/tip_read/', null, { params: { tip_instance_id } }).then((response: any) => {
        return { result: this.entityModel.deserialize(response), params: params }
      })
    }

    checkBlockId () : Promise<null | string> {
      return this.get(this.endpoint + 'active_date/', {}).then((response: any) => {
        return response.schedule_block
      })
    }
}
