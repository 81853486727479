import QuestionModel from '../../../shared/src/models/entities/questionModel'
import { BaseRepository } from '../../../shared/src/repositories/baseRepository'

export default class QuestionRepository extends BaseRepository<QuestionModel> {
  id= 'questions'
  endpoint= '/questions/'
  entityModel= QuestionModel
  publicGet= true

  retireQuestion (question: QuestionModel): Promise<any> {
    return this.post(this.endpoint + question.id + '/' + 'retire/', null).then((response: any) => {
      return response
    })
  }

  reactivateQuestion (question: QuestionModel): Promise<any> {
    return this.post(this.endpoint + question.id + '/' + 'reactivate/', null).then((response: any) => {
      return response
    })
  }

  questionInstances (questionId: string): Promise<any> {
    return this.get(this.endpoint + questionId + '/' + 'instances/', {}, true).then((response: any) => {
      return response
    })
  }

  questionInstanceResults (questionInstanceId: string, weighted: boolean = false, filtered: boolean = false, property: string = '', options: string = ''): Promise<any> {
    if (weighted) {
      return this.get(this.endpoint + questionInstanceId + '/' + 'weighted_results/', {}, true).then((response: any) => {
        return response
      })
    } else if (filtered) {
      if (property !== '' && options !== '') {
        return this.get(this.endpoint + questionInstanceId + '/' + 'filtered_results/?property=' + property + '&options=' + options, {}, true).then((response: any) => {
          return response
        })
      } else {
        return this.get(this.endpoint + questionInstanceId + '/' + 'demographic_results/', {}, true).then((response: any) => {
          return response
        })
      }
    } else {
      return this.get(this.endpoint + questionInstanceId + '/' + 'results/', {}, true).then((response: any) => {
        return response
      })
    }
  }
}
