











import QuestionRepository from '@youthapp/dashboard/src/repositories/QuestionRepository'
import QuestionModel from '@youthapp/shared/src/models/entities/questionModel'
import usePermissions from '../../composables/usePermissions'
import { defineComponent } from '@vue/composition-api'
import { RepositoryFactory } from '@youthapp/shared'

export default defineComponent({
  name: 'retire-question',
  props: {
    question: {
      type: QuestionModel,
      required: true
    }
  },
  setup (props, context) {
    const { can } = usePermissions()

    const retireQuestion = (question: QuestionModel) => {
      RepositoryFactory.get(QuestionRepository).retireQuestion(question).then(() => {
        event()
      })
    }

    const reactivateQuestion = (question: QuestionModel) => {
      RepositoryFactory.get(QuestionRepository).reactivateQuestion(question).then(() => {
        event()
      })
    }

    const event = () => {
      context.emit('reloadData', true)
    }

    return {
      reactivateQuestion,
      retireQuestion,
      can
    }
  }
})
