

















import { Ref, ref, defineComponent, PropType } from '@vue/composition-api'
import { BaseEntityModel } from '../../../../shared/src/models/entities/baseEntityModel'
import { BaseRepository, repoParams } from '../../../../shared/src/repositories/baseRepository'
import useRepository, { callTypes } from '../../composables/useRepository'
import BackButton from '../buttons/backButton.vue'
import { useRouter } from '../../composables/useRouter'
import useToast from '../../composables/useToast'
import i18n from '../../i18n'
import usePermissions from '../../composables/usePermissions'
import useGlobalLoading from '../../composables/useGlobalLoading'

export default defineComponent({
  name: 'base-form',
  components: {
    'back-button': BackButton
  },
  data () {
    return {
      formData: {
        type: { value: 'SELECT' }
      } // define formData in data option
    }
  },
  props: {
    defaultValue: {
      type: Object as PropType<BaseEntityModel>,
      required: true
    },
    repo: {
      type: Function as PropType<new (...params: any[]) => BaseRepository<BaseEntityModel>>,
      required: true
    },
    paramIdentifier: {
      type: String,
      required: true
    },
    redirectRoute: {
      type: String,
      required: true
    },
    editRoute: {
      type: String,
      required: true
    },
    redirectWithId: {
      type: String
    },
    historyModal: {
      type: Boolean,
      default: false
    },
    isFromDayView: {
      type: Boolean,
      required: false,
      default: false
    },
    isQuestionForm: {
      type: Boolean,
      default: false
    }
  },
  setup ({ repo, defaultValue, redirectRoute, isFromDayView }, { emit, root }) {
    const { can } = usePermissions()
    const { route, router } = useRouter()
    const { doCall, result, loading } = useRepository<typeof defaultValue>(
      repo,
      callTypes.getSingel,
      { id: route.value.params.id }
    )

    const isEdit = !!route.value.params.id
    const redirectOnSave = ref<Boolean>(true)
    const toast = useToast(root)
    const saving = ref<Boolean>(false)
    useGlobalLoading(loading)
    if (isEdit) {
      doCall()
    }

    const form : Ref<BaseEntityModel | undefined> = (isEdit) ? result : ref(defaultValue)

    const onSubmit = async () : Promise<void> => {
      let repoParams : repoParams = {}

      if (isEdit && result.value) {
        repoParams = {
          id: result.value.id,
          model: result.value
        }
      } else {
        repoParams = {
          model: form.value
        }
      }

      const postRepo = useRepository<typeof defaultValue>(
        repo,
        isEdit ? callTypes.update : callTypes.create,
        repoParams
      )

      postRepo.doCall().then((success: Boolean) => {

        if (isEdit) {
          toast.send(i18n.t('toast-messages.edited').toString())
        } else {
          toast.send(i18n.t('toast-messages.created').toString())
        }

        emit('submitSuccess', postRepo.result.value)
        if (success && redirectRoute && redirectOnSave.value && !isFromDayView) {
          router.push({ path: '/' + redirectRoute })
        } else {
          redirectOnSave.value = true
          saving.value = false
          if (!isFromDayView) {
            toast.send('Opgeslagen')
            if (!isEdit) {
              router.push({ path: '/' + redirectRoute + '/' })
            }
          }
          if (isFromDayView) {
            emit('tipAdded', postRepo?.result?.value)
            emit('questionAdded', postRepo?.result?.value)
          }
        }
      }).catch((e) => {
        saving.value = false
        e && toast.send('Opslagen niet gelukt', 'danger')
      })
    }

    const customHandleSubmit = (handleSubmit, validate) => {
      validate().then((valid: boolean) => {
        if (valid) {
          handleSubmit(onSubmit)
        } else {
          saving.value = false
          toast.send('Niet alle velden zijn correct ingevuled', 'danger')
        }
      })
    }

    const saveWithMultipleAnswers = (formData, handleSubmit, validate) => {
      validate().then((valid: boolean) => {
        if (valid) {
          const isMultiple = true // define isMultiple before using it
          formData.isMultiple = true
          formData.type.value = 'MULTISELECT'
          formData.type = { value: 'MULTISELECT' }
          emit('set-multiselect-type') // emit event for setting Multiselect
          handleSubmit(onSubmit)
        } else {
          saving.value = false
          toast.send('Niet alle velden zijn correct ingevuled', 'danger')
        }
      })
    }

    return {
      onSubmit,
      form,
      customHandleSubmit,
      saveWithMultipleAnswers,
      saving,
      loading,
      can
    }
  }
})
