












































import DemographicQuestionRepository from '@youthapp/shared/src/repositories/entities/demographicQuestionRepository'
import TargetDemographicModel from '@youthapp/shared/src/models/entities/targetDemographicModel'
import { BaseEntityModel } from '@youthapp/shared/src/models/entities/baseEntityModel'
import useRepository, { callTypes } from '@youthapp/dashboard/src/composables/useRepository'
import MultiSelectInput from '../inputs/MultiSelectInput.vue'
import { defineComponent, ref, PropType } from '@vue/composition-api'
import OptionsModel from '@youthapp/shared/src/models/entities/optionsModel'
import DemographicQuestionModel from '@youthapp/shared/src/models/entities/demographicQuestionModel'
import PropertyModel from '@youthapp/shared/src/models/entities/propertyModel'
import usePermissions from '../../composables/usePermissions'

export default defineComponent({
  components: {
    'multi-select-input': MultiSelectInput
  },
  name: 'demographic-conditions',
  props: {
    coverage: {
      type: Number,
      required: false
    },
    target_demographics: {
      type: Array,
      required: false
    },
    isDisplayOnly: {
      type: Boolean,
      required: false
    },
    options: {
      type: Array as PropType<Array<DemographicQuestionModel>>
    }
  },
  setup (props, context) {
    const { can } = usePermissions()
    const demographicOptions = ref<any>([])
    const selectedDemographicQuestion = ref<any>()
    const targetDemographicsInput = ref<Array<TargetDemographicModel>>([])
    if (props?.target_demographics) {
      targetDemographicsInput.value = BaseEntityModel.mapEntityArray(props.target_demographics, TargetDemographicModel)
    }

    const initialTargetDemographics = ref<TargetDemographicModel[]>([])
    const getDemographicQuestions = () => {
      if (targetDemographicsInput.value.length > 0) {
        targetDemographicsInput.value.forEach((targetDemographicInput: TargetDemographicModel) => {
          const { result, doCall } = useRepository<any>(
            DemographicQuestionRepository,
            callTypes.getSingel,
            { id: targetDemographicInput?.property?.id }
          )
          doCall().then(() => {
            const newTargetDemographic = new TargetDemographicModel(undefined, result?.value?.options, new PropertyModel(result?.value?.id, result?.value?.key, result?.value?.order, result?.value?.created_at))
            if (newTargetDemographic?.options) {
              newTargetDemographic.options.forEach((newTargetOption: OptionsModel) => {
                if (targetDemographicInput?.options) {
                  targetDemographicInput.options.forEach((inputOption : OptionsModel) => {
                    if (newTargetOption.id === inputOption.id) {
                      newTargetOption.isChecked = true
                    }
                  })
                }
              })
            }
            initialTargetDemographics.value.push(newTargetDemographic)
          })
        })
      }
    }

    getDemographicQuestions()

    const getDemographicOptionsForMultiSelect = () => {
      if (props?.options) {
        props.options.forEach(result => {
          if (props?.target_demographics) {
            if (!props.target_demographics.find((d: any) => d?.property?.id === result?.id)) {
              // Add to multi select when the demographic is not used in this question yet
              demographicOptions.value.push(result)
            }
          }
        })
      }
    }

    const addDemoGraphicQuestion = (selected: DemographicQuestionModel) => {
      const index = demographicOptions.value.indexOf(selected)
      if (index > -1) {
        demographicOptions.value.splice(index, 1)
        selectedDemographicQuestion.value = null
      }
      const { result, doCall } = useRepository(
        DemographicQuestionRepository,
        callTypes.getSingel,
        { id: selected.id }
      )
      doCall().then(() => {
        const newOptions: Array<any> = []
        if (result?.value?.options) {
          result.value.options.forEach((option) => {
            option.isChecked = false
            newOptions.push(option)
          })
        }
        const newTargetDemographic = new TargetDemographicModel(undefined, newOptions, new PropertyModel(result?.value?.id, result?.value?.key, result?.value?.order, result?.value?.created_at))
        initialTargetDemographics.value.push(newTargetDemographic)
      })
    }

    const patchOptions = () => {
      context.emit('patchOptions', initialTargetDemographics.value)
    }

    const setClass = (isChecked: boolean) => {
      return isChecked ? 'success' : 'danger'
    }

    const removeDemographicQuestion = (index: number, initialTargetDemographics: any) => {
      if (index > -1) {
        initialTargetDemographics.splice(index, 1)
        patchOptions()
      }
    }

    getDemographicOptionsForMultiSelect()

    return {
      selectedDemographicQuestion,
      initialTargetDemographics,
      removeDemographicQuestion,
      addDemoGraphicQuestion,
      demographicOptions,
      patchOptions,
      setClass,
      can
    }
  }
})
