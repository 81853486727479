import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class TipTypeModel extends BaseEntityModel implements EntityModel<TipTypeModel> {
  constructor (
      public id?: string,
      public value?: string,
      public text?: string
  ) {
    super(id)
  }

  public static deserialize (input: any): TipTypeModel {
    return new TipTypeModel(
      input.id,
      input.value,
      input.label
    )
  }
}

