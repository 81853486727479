














































































































































import DemographicClusterRepositoryRepository from '@youthapp/shared/src/repositories/entities/demographicClusterGroupsRepository'
import DemographicQuestionRepository from '@youthapp/shared/src/repositories/entities/demographicQuestionRepository'
import DemographicClusterGroupModel from '@youthapp/shared/src/models/entities/demographicClusterGroupModel'
import DemographicQuestionModel from '@youthapp/shared/src/models/entities/demographicQuestionModel'
import { BaseEntityModel } from '@youthapp/shared/src/models/entities/baseEntityModel'
import useRepository, { callTypes } from '@youthapp/dashboard/src/composables/useRepository'
import OptionsModel from '@youthapp/shared/src/models/entities/optionsModel'
import TextInput, { inputTypes } from '../../inputs/TextInput.vue'
import MultiSelectInput from '../../inputs/MultiSelectInput.vue'
import usePermissions from '../../../composables/usePermissions'
import { useRouter } from '../../../composables/useRouter'
import { defineComponent, ref } from '@vue/composition-api'
import BaseForm from '../../base-views/baseForm.vue'
import NoAccess from '../NoAccess.vue'
import moment from 'moment'

export default defineComponent({
  name: 'demographic-question-form',
  components: {
    'base-form': BaseForm,
    'text-input': TextInput,
    'no-access': NoAccess,
    'multi-select-input': MultiSelectInput
  },
  setup () {
    const demographicClusterGroups = ref<Array<DemographicClusterGroupModel>>([])
    const selectedDemographicClusterGroup = ref<any>()
    const { can } = usePermissions()
    const { route } = useRouter()
    const demographicId = route.value.params.id

    const defaultOptions = [
      { key: '', weight: '1' },
      { key: '', weight: '1' }
    ]

    let defaultValue

    if (!route.value.params.data) {
      defaultValue = DemographicQuestionModel.deserialize({
        id: null,
        key: null,
        order: 100,
        options: BaseEntityModel.mapEntityArray(defaultOptions, OptionsModel),
        created_at: null,
        question_type: 'SELECT',
        question_content: '',
        isMultiple: false
      })
    }

    if (route.value.params.data) {
      defaultValue = route.value.params.data
    }

    const dateFormat = (date: string) => {
      date = moment(date, 'YYYY-MM-DD H:mm').format('D MMMM YYYY')
      return date
    }
    const displayIndex = (index: number) => {
      return index + 1
    }

    const extraOptionKeyField = (options: OptionsModel[]) => {
      options.push(new OptionsModel())
    }

    const removeOptionKeyField = (index: number, options: Array<string>) => {
      if (options.length > 1) {
        options.splice(index, 1)
      }
    }

    const moveOptionKeyField = function (from, to, options) {
      options.splice(to, 0, options.splice(from, 1)[0])
    }

    const hideOptionKey = (option: OptionsModel) => {
      // console.log('Hiding option...', option)
    }

    const fetchDemographicClusterGroups = () => {
      const { doCall, results } = useRepository<typeof defaultValue>(
        DemographicClusterRepositoryRepository,
        callTypes.getModelArray
      )

      doCall().then(() => {
        demographicClusterGroups.value = results.value
      })

    }

    fetchDemographicClusterGroups()

    return {
      selectedDemographicClusterGroup,
      DemographicQuestionRepository,
      demographicClusterGroups,
      removeOptionKeyField,
      extraOptionKeyField,
      moveOptionKeyField,
      hideOptionKey,
      demographicId,
      displayIndex,
      defaultValue,
      inputTypes,
      dateFormat,
      can
    }
  }
})
