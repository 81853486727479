import EntityModel from '../../interfaces/entityModel'
import BadgeModel, { BadgeModelStorage } from './BadgeModel'
import { BaseEntityModel } from './baseEntityModel'

export type BadgeStatsModelStorage = {
    id: string
    statuses: BadgeModelStorage[],
    statistics: {
      questions_answered: number,
      tips_read: number,
      current_response_streak: number
    }
}

export default class BadgeStatsModel extends BaseEntityModel implements EntityModel<BadgeStatsModel> {
  constructor (
     public id: string,
     public badges: BadgeModel[],
     public questionsAnswered : number,
     public tipsRead : number,
     public currentResponseStreak : number
  ) {
    super(id)
  }

  public static deserialize (input: any): BadgeStatsModel {
    return new BadgeStatsModel(
      'STATS_OF_THE_DAY',
      this.mapEntityArray(input.statuses, BadgeModel),
      input.statistics.questions_answered,
      input.statistics.tips_read,
      input.statistics.current_response_streak
    )
  }

  public serialize () : Object {

    return {

    }
  }

  public serializeForStorage () : BadgeStatsModelStorage {

    const badges : BadgeModelStorage[] = []

    this.badges.forEach((badge: BadgeModel) => {
      badges.push(badge.serializeForStorage())
    })

    return {
      id: this.id,
      statuses: badges,
      statistics: {
        questions_answered: this.questionsAnswered,
        tips_read: this.tipsRead,
        current_response_streak: this.currentResponseStreak
      }
    }
  }

}

