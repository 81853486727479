<template>
  <div>
    <feedback-table :isSelectable="false"/>
  </div>
</template>

<script>
import Vue from 'vue'
import FeedbackTable from '../../components/tables/feedbackTable'
import usePermissions from '../../composables/usePermissions'

export default Vue.extend({
  name: 'Feedback',
  components: {
    'feedback-table': FeedbackTable
  },
  setup () {
    const { can } = usePermissions()
    return {
      can
    }
  }
})
</script>
