import TagModel from '@youthapp/shared/src/models/entities/tagModel'
import { BaseRepository } from '@youthapp/shared/src/repositories/baseRepository'
import axios from 'axios'

export default class TagRepository extends BaseRepository<TagModel> {
  id = 'tags'
  endpoint = '/tags/'
  entityModel = TagModel
  publicGet = false

  async deleteTag (tagId: string): Promise<void> {
    const response = await this.delete(this.endpoint + tagId + '/')
  }
}
