<template>
  <div v-if="can('questions.view_question')">
    <questions-table :isSelectable="false"/>
  </div>
</template>

<script>
import Vue from 'vue'
import QuestionsTable from '../../components/tables/questionsTable'
import usePermissions from '../../composables/usePermissions'

export default Vue.extend({
  name: 'Questions',
  components: {
    'questions-table': QuestionsTable
  },
  setup () {
    const { can } = usePermissions()
    return {
      can
    }
  }
})
</script>
