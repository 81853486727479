















import Vue from 'vue'
import { useRouter } from '../../composables/useRouter'

export default Vue.extend({
  name: 'custom-header',
  components: {
    // 'select-input': SelectInput
  },
  setup () {
    const { pageTitle } = useRouter()
    return {
      pageTitle
    }
  }
})
