import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class QuestionStatusModel extends BaseEntityModel implements EntityModel<QuestionStatusModel> {
  constructor (
      public id?: string,
      public value?: string,
      public text?: string
  ) {
    super(id)
  }

  public static deserialize (input: any): QuestionStatusModel {
    return new QuestionStatusModel(
      input.id,
      input.value,
      input.label
    )
  }
}

