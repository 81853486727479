import ScheduleBlockModel from '@youthapp/shared/src/models/entities/scheduleBlocksModel'
import { BaseRepository } from '@youthapp/shared/src/repositories/baseRepository'
export class ScheduleBlockRepository extends BaseRepository<ScheduleBlockModel> {
  id = '/schedule_blocks/'
  endpoint = '/schedule_blocks/'
  entityModel = ScheduleBlockModel
  publicGet = false

  activeScheduleBlock (): Promise<any> {
    return this.get(this.endpoint + 'active/')
  }

  exportScheduleBlock (id: string): Promise<any> {
    return this.get(this.endpoint + id + '/report/', { responseType: 'blob' })
  }
}
