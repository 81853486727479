


















































import { ScheduleBlockRepository } from '../../../shared/src/repositories/entities/scheduleBlocksRepository'
import { filterObject, repoParams } from '../../../shared/src/repositories/baseRepository'
import ScheduleBlockModel from '../../../shared/src/models/entities/scheduleBlocksModel'
import useRepository, { callTypes } from '@youthapp/dashboard/src/composables/useRepository'
import useGlobalLoading from '../composables/useGlobalLoading'
import { defineComponent, ref } from '@vue/composition-api'
import usePermissions from '../composables/usePermissions'
import { useRouter } from '../composables/useRouter'
import moment from 'moment'


enum WeekDirection {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS'
}

export default defineComponent({
  name: 'Calendar',
  data () {
    return {
      items: [
        { id: 'fc288293-9c1a-4b54-a28c-57d61b81e860', content: 'A question', status: 'nieuw' },
        { id: 'dfb35ff4-d712-404d-bd75-d9e3c17718ca', content: 'A question 2', status: 'gebruikt' }
      ]
    }
  },
  setup () {
    const { can } = usePermissions()
    moment.locale('nl-be')
    const activeDay = ref<string>('')
    const weekNumber = ref<Number>(0)
    const week = ref<Array<any>>([])
    let currentDate = moment()
    const currentDay = ref<any>()
    const startDate = ref<string>('')
    const endDate = ref<string>('')
    enum Filters { startDate = 'startDate', endDate = 'endDate' }
    const { router, route } = useRouter()
    const urlDay = route.value.params.currentDay

    const beforeRouteUpdate = (to, from) => {
      // check if the currentDay parameter has changed
      if (to.params.currentDay !== currentDay.value) {
        currentDay.value = to.params.currentDay
      }
    }
    const created = () => {
      // set the currentDay variable to the currentDay parameter
      // currentDay.value = this.$route.params.currentDay
      currentDay.value = route.value.params.currentDay
    }

    const navigateTowardsDay = (weekDay: any) => {
      const formattedDate = weekDay.day + ' ' + weekDay.year
      let parsedDate = moment(formattedDate, 'dddd D MMMM YYYY', 'nl', true)
      if (!parsedDate.isValid()) {
        parsedDate = moment(weekDay.day, 'dddd D MMMM', 'nl', true)
      }
      if (parsedDate.isValid()) {
        const x = parsedDate.format('YYYY-MM-DD')
        router.push({ name: 'day', params: { date: x } })
      }
    }

    const filters : filterObject = {
      startDate: { type: 'string', value: startDate.value, filterKey: 'start' },
      endDate: { type: 'string', value: endDate.value, filterKey: 'end' }
    }

    const params : repoParams = { filters: filters }

    const { loading, results, doCall } = useRepository<ScheduleBlockModel>(
      ScheduleBlockRepository,
      callTypes.getModelArray,
      params
    )

    useGlobalLoading(loading)

    const getCurrentDay = () => {
      currentDay.value = currentDate
    }

    const getActiveDay = () => {
      const params: repoParams = { id: 'active/' }

      const { result, doCall } = useRepository<ScheduleBlockModel>(
        ScheduleBlockRepository,
        callTypes.getSingel,
        params
      )
      doCall().then(() => {
        activeDay.value = moment(result?.value?.date, 'YYYY-MM-DD').format('dddd D MMMM')
        if (urlDay) {
          currentDate = moment(urlDay, 'YYYY-MM-DD')
        } else {
          currentDate = moment(result?.value?.date)
        }
      }).catch((e: { response }) => {
        if (urlDay) {
          currentDate = moment(urlDay, 'YYYY-MM-DD')
        } else {
          currentDate = moment(e.response.data.active_date)
        }
        activeDay.value = moment(e.response.data.active_date, 'YYYY-MM-DD').format('dddd D MMMM YYYY')
      }).finally(() => {
        getCurrentDay()
        getCurrentWeek()
        getCurrentWeekNumber()
      })
    }

    const getCurrentWeek = () => {
      week.value = []
      const weekStart = currentDate.clone()
      const weekEnd = currentDate.clone().add(7, 'days')

      startDate.value = weekStart.format().substr(0, weekStart.format().indexOf('T')) + 'T00:00:00Z'
      endDate.value = weekEnd.format().substr(0, weekEnd.format().indexOf('T')) + 'T22:00:00Z'

      filters[Filters.startDate] = { type: 'string', value: startDate.value, filterKey: 'start' }
      filters[Filters.endDate] = { type: 'string', value: endDate.value, filterKey: 'end' }

      doCall().then(() => {
        for (var i = 0; i <= 6; i++) {
          const weekDay : any = {
            day: moment(weekStart).add(i, 'days').format('dddd D MMMM'),
            year: moment(weekStart).add(i, 'days').format('YYYY'),
            scheduleBlock: null
          }
          const tmpDay = moment(weekStart).add(i, 'days').format()
          for (let i = 0; i < results.value.length; i++) {
            if (results.value[i].date === tmpDay.substr(0, tmpDay.indexOf('T'))) {
              weekDay.scheduleBlock = results.value[i]
            }
          }

          week.value.push(weekDay)
        }
      })
    }

    const getCurrentWeekNumber = () => {
      weekNumber.value = moment(currentDate).isoWeek()
    }

    const changeWeek = (change: WeekDirection) => {
      if (change === 'NEXT') {
        currentDate.add(7, 'days')
      }
      if (change === 'PREVIOUS') {
        currentDate.subtract(7, 'days')
      }
      router.push({ name: 'calendar', params: { currentDay: currentDate.format('YYYY-MM-DD') } })
      getCurrentDay()
      getCurrentWeek()
      getCurrentWeekNumber()
    }
    getActiveDay()

    return {
      navigateTowardsDay,
      getCurrentWeekNumber,
      activeDay,
      WeekDirection,
      changeWeek,
      weekNumber,
      currentDay,
      week,
      beforeRouteUpdate,
      created,
      can
    }
  },

  onBeforeRouteUpdate () {
    return this.beforeRouteUpdate
  },
  created () {
    return this.created
  }
})
