










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'no-access',
  props: {
    isDisplay: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    return {
    }
  }
})
