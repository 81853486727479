import Serializable from '../../interfaces/serializable'
import ApiConfig from './apiConfig'
import FeaturesConfig from './featuresConfig'
import OidcConfig from './oidcConfig'
// import SentryConfig from './sentryConfig'


export class MasterConfig implements Serializable<MasterConfig> {
  api !: ApiConfig
  oidc!: OidcConfig
  features!: FeaturesConfig
  //   sentry!: SentryConfig

  deserialize (input: any) {
    if (input.api) {
      this.api = new ApiConfig().deserialize(input.api)
    }
    if (input.oidc) {
      this.oidc = new OidcConfig().deserialize(input.oidc)
    }
    if (input.features) {
      this.features = new FeaturesConfig().deserialize(input.features)
    }
    // if (input.sentry) {
    //   this.sentry = new SentryConfig().deserialize(input.sentry)
    // }

    return this
  }
}
