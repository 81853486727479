<template>
  <div id="app">
    <global-loader />
    <b-row>

      <b-col cols="2" class="pr-0">
        <side-bar />
      </b-col>

      <b-col cols="10" class="px-0 bg-light">
        <custom-header />
        <main class="px-2">
          <base-page>
            <router-view/>
          </base-page>
        </main>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import GlobalLoader from './components/semantic/GlobalLoader.vue'
import CustomHeader from './components/semantic/CustomHeader'
import BasePage from './components/semantic/BasePage.vue'
import Sidebar from './components/semantic/Sidebar.vue'

export default {
  name: 'YouthApp',
  components: {
    'side-bar': Sidebar,
    'base-page': BasePage,
    'custom-header': CustomHeader,
    'global-loader': GlobalLoader
  }
}
</script>

<style lang="scss">
#app>.row{
  min-height: 100vh;
}

#app > .row{
  max-width: 100vw;
}

#app {
  background: #EFF3F5 !important;
  overflow-x: hidden;
}

body {
  background: #EFF3F5 !important;
}
</style>
