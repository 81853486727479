import DemographicClusterGroupsModel from '../../models/entities/demographicClusterGroupModel'
import DemographicQuestionModel from '../../models/entities/demographicQuestionModel'
import { BaseRepository } from '../../repositories/baseRepository'

export default class DemographicClusterGroupsRepository extends BaseRepository<DemographicQuestionModel> {
  id = 'demographic_cluster_groups'
  endpoint = '/demographic_cluster_groups/'
  entityModel = DemographicClusterGroupsModel
  publicGet = false
}
