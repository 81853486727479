import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class ScheduleBlockStateModel extends BaseEntityModel implements EntityModel<ScheduleBlockStateModel> {
  constructor (
      public id?: string,
      public value?: string,
      public label?: string
  ) {
    super(id)
  }

  public static deserialize (input: any): ScheduleBlockStateModel {
    return new ScheduleBlockStateModel(
      input.id,
      input.value,
      input.label
    )
  }

  public serialize () : Object {
    return {
      id: this.id,
      value: this.value,
      label: this.label
    }
  }
}

