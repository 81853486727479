import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import DemographicClusterGroupsModel from './demographicClusterGroupModel'

export default class OptionsModel extends BaseEntityModel implements EntityModel<OptionsModel> {
  constructor (
    public id?: string,
    public key?: string,
    public weight?: number,
    public cluster_group?: any,
    public isChecked: boolean = false
  ) {
    super(id)
  }

  public static deserialize (input: any): OptionsModel {
    return new OptionsModel(
      input.id,
      input.key,
      Number(input.weight),
      input.cluster_group ? new DemographicClusterGroupsModel(input.cluster_group, input.cluster_group, input.cluster_group) : null
    )
  }

  public serialize () : Object {
    return {
      key: this.key,
      weight: this.weight?.toString(),
      cluster_group: this.cluster_group
    }
  }
}

