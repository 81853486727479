










































































import { FeedbackRepository } from '@youthapp/shared/src/repositories/entities/feedbackRepository'
import FeedbackModel from '@youthapp/shared/src/models/entities/feedbackModel'
import QuestionModel from '@youthapp/shared/src/models/entities/questionModel'
import useRepository, { callTypes } from '@youthapp/dashboard/src/composables/useRepository'
import AnswerModel from '@youthapp/shared/src/models/entities/answerModel'
import TipModel from '@youthapp/shared/src/models/entities/tipModel'
import useGlobalLoading from '../../composables/useGlobalLoading'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { useRouter } from '../../composables/useRouter'
import BackButton from '../buttons/backButton.vue'
import { RepositoryFactory } from '@youthapp/shared'
import usePermissions from '../../composables/usePermissions'

export default defineComponent({
  name: 'feedback-view',
  components: {
    'back-button': BackButton
  },
  props: {
    detailedFeedbackId: {
      type: String,
      required: false
    }
  },
  setup (props, { emit }) {
    const { can } = usePermissions()
    const isRead = ref<boolean>()
    const feedback = ref<FeedbackModel>()
    const newQuestion = ref<any>(new QuestionModel())
    const newTip = ref<any>(new TipModel())
    const { route, router } = useRouter()

    const fetchFeedback = () => {
      if (props.detailedFeedbackId) {
        const { doCall, result, loading } = useRepository(
          FeedbackRepository,
          callTypes.getSingel,
          { id: props.detailedFeedbackId }
        )
        useGlobalLoading(loading)

        doCall().then(() => {
          feedback.value = result.value
          checkCheckbox()
        })
      }
    }

    fetchFeedback()

    watch(() => props.detailedFeedbackId, () => {
      fetchFeedback()
    })

    const checkCheckbox = () => {
      if (feedback?.value?.status === 'NEW') {
        isRead.value = false
      } else {
        isRead.value = true
      }
    }

    const navigateToQuestionForm = (feedback: FeedbackModel) => {
      newQuestion.value.content = feedback.content
      newQuestion.value.answerOptions = []
      if (feedback.answers) {
        feedback.answers.forEach((answer: string) => {
          const answerOption = new AnswerModel(undefined, answer, undefined)
          if (newQuestion?.value?.answerOptions) {
            newQuestion.value.answerOptions.push(answerOption)
          }
        })
        if (!feedback.isAnonymous) {
          newQuestion.value.proposed_by = feedback.proposedBy
        }
      }
      router.push({ name: 'question', params: { data: newQuestion.value } })
    }

    const navigateToTipForm = (input: any) => {
      newTip.value.content = input
      router.push({ name: 'tip', params: { data: newTip.value } })
    }

    const patchStatus = (id: string) => {
      let prefix

      if (!isRead.value) {
        prefix = 'reopen'
      }

      if (isRead.value) {
        prefix = 'complete'
      }

      RepositoryFactory.get(FeedbackRepository).complete(id, prefix).then((res) => {
        if (feedback?.value?.status) {
          feedback.value.status = res.status
        }
      })
    }

    const previousFeedback = () => {
      emit('previousFeedback', true)
    }

    const nextFeedback = () => {
      emit('nextFeedback', true)
    }
    return {
      navigateToQuestionForm,
      navigateToTipForm,
      previousFeedback,
      nextFeedback,
      patchStatus,
      feedback,
      isRead,
      can
    }

  }
})
